import React, { useState } from 'react';
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';
import { motion } from 'framer-motion';
import {
  Accordion,
  ColOne,
  ColTwo,
  Container,
  FAQSectionWrapper,
} from './FAQSection.styles';
import chisiamo from '../assets/images/lastPic.webp';
import faq from '../assets/images/faqImg.avif';

function FAQSection() {
  const [selected, setSelected] = useState(1);
  const cardVariants = {
    offscreen: { opacity: 0, y: -20 },
    onscreen: {
      opacity: 1,
      y: 0,

      transition: {
        duration: 0.7,
      },
    },
  };

  const toggleAccordion = (select) => {
    if (selected === select) {
      setSelected(0);
    } else {
      setSelected(select);
    }
  };

  return (
    <FAQSectionWrapper>
      <Container>
        <ColOne id="chi-siamo">
          <motion.h3
            variants={cardVariants}
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.6 }}
          >
            Chi Siamo
          </motion.h3>
          <motion.p
            variants={cardVariants}
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.5 }}
          >
            <span>Storia generale</span>
            Diventare il primo ecosistema phygital nel settore assicurativo, dove ogni componente - dai clienti agli intermediari, dalle aziende ai fornitori - coesiste e prospera in un ambiente integrato, sostenibile e tecnologicamente avanzato.
            <br />
            <br />
            <span>Mission</span>
            La nostra missione è guidare la trasformazione dell'assicurazione, spingendo i confini tra il mondo fisico e digitale. Greenia si impegna a rivoluzionare l'esperienza assicurativa attraverso soluzioni innovative e uniche. Siamo più di un intermediario assicurativo digitale; siamo il motore di un nuovo ecosistema assicurativo phygital.
            <br />
            <br />
            <span>Vision</span>
            Immaginiamo un futuro in cui l'assicurazione è integrata in modo perfetto nella vita quotidiana delle persone, con un equilibrio perfetto tra il mondo fisico e digitale. Greenia aspira a essere il pioniere dell'ecosistema assicurativo phygital, creando un ambiente in cui la collaborazione tra assicuratori e tecnologia digitale è fondamentale per un'assicurazione più accessibile, efficiente e personalizzata. La nostra visione è per un'industria assicurativa in cui l'innovazione e la connettività phygital diventano la nuova normalità, migliorando la fiducia del cliente e aprendo nuove frontiere di possibilità.
          </motion.p>
          <img src={chisiamo} alt="Chi Siamo" />
        </ColOne>
        <ColTwo>
          <img src={faq} alt="FAQ Foto" className="desktop mb-4" />
          <motion.h3
            id="faq"
            variants={cardVariants}
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.6 }}
            className="mt-4"
          >
            Domande Frequenti
          </motion.h3>
          <Accordion>
            <motion.div className="h5-accordion" initial={false} onClick={() => toggleAccordion(1)}>
              <span>Cos'è Greenia?</span>
              {selected === 1 ? (
                <FaCaretDown color="white" />
              ) : (
                <FaCaretUp color="white" />
              )}
            </motion.div>
            {selected === 1 ? (
              <motion.p
                className="open"
                initial={{ opacity: 0 }}
                animate={{
                  opacity: 1,
                  transition: {
                    duration: 0.6,
                  },
                }}
                exit={{ opacity: 0 }}
              >
                Immagina Greenia come la tua "tecnologia-assistente" nel mondo
                assicurativo, pronto a semplificare e modernizzare la tua
                operatività. In un colpo solo, avrai un portale all'avanguardia
                per gestire il tuo portafoglio, oltre a sbirciare tra le
                migliori tariffe di mercato con un sorriso. Unisciti alla
                squadra degli "smart player" italiani. Scommettiamo che sarà
                un'esperienza da campioni?
              </motion.p>
            ) : (
              ''
            )}
            <div className="h5-accordion" onClick={() => toggleAccordion(2)}>
              <span>Come posso emettere contratti con Greenia?</span>
              {selected === 2 ? (
                <FaCaretDown color="white" />
              ) : (
                <FaCaretUp color="white" />
              )}
            </div>
            {selected === 2 ? (
              <motion.p
                className="open"
                initial={{ opacity: 0 }}
                animate={{
                  opacity: 1,
                  transition: {
                    duration: 0.8,
                  },
                }}
                exit={{ opacity: 0 }}
              >
                Semplicità allo stato puro! Grazie alla nostra piattaforma
                digitale all'avanguardia e al consulente dedicato a te, sarà un
                gioco da ragazzi definire con il cliente il prodotto perfetto
                per le sue esigenze. Dimentica carta e attese interminabili: ora
                puoi emettere i prodotti delle principali Compagnie assicurative
                in tempo reale. Con Greenia, la velocità è il nostro motto!
              </motion.p>
            ) : (
              ''
            )}
            <div className="h5-accordion" onClick={() => toggleAccordion(3)}>
              <span>Ho un supporto dedicato?</span>
              {selected === 3 ? (
                <FaCaretDown color="white" />
              ) : (
                <FaCaretUp color="white" />
              )}
            </div>
            {selected === 3 ? (
              <motion.p
                className="open"
                initial={{ opacity: 0 }}
                animate={{
                  opacity: 1,
                  transition: {
                    duration: 0.8,
                  },
                }}
                exit={{ opacity: 0 }}
              >
                Assolutamente! Sin dai primi passi, avrai il tuo Account
                personale sempre al tuo fianco: sarai la nostra priorità.
                Insieme, creerete strategie vincenti per la gestione del
                portafoglio e affronterete anche le sfide più intricate. E non
                temere se il tuo consulente dovesse essere momentaneamente
                occupato, perché un team di esperti è pronto a entrare in campo
                per darti una mano. Con Greenia la tua squadra è sempre pronta a
                sostenerti!
              </motion.p>
            ) : (
              ''
            )}
            <div className="h5-accordion" onClick={() => toggleAccordion(4)}>
              <span>I documenti di collaborazione?</span>
              {selected === 4 ? (
                <FaCaretDown color="white" />
              ) : (
                <FaCaretUp color="white" />
              )}
            </div>
            {selected === 4 ? (
              <motion.p
                className="open"
                initial={{ opacity: 0 }}
                animate={{
                  opacity: 1,
                  transition: {
                    duration: 0.8,
                  },
                }}
                exit={{ opacity: 0 }}
              >
                Facciamo squadra per tenere ogni cosa sotto controllo e a
                portata di clic! Hai l'accesso ai documenti tuoi e dei tuoi
                clienti, pronti per il download in qualsiasi momento. E addio
                alla carta: la firma digitale fa il suo ingresso, eliminando la
                necessità di stampare qualsiasi cosa. E se dovessi avere bisogno
                di una mano, il tuo Account personale è pronto all'azione. Con
                lui al timone, non c'è sfida troppo grande da affrontare.
                Greenia è qui per semplificare la tua vita assicurativa!
              </motion.p>
            ) : (
              ''
            )}
            <div className="h5-accordion" onClick={() => toggleAccordion(5)}>
              <span>
                Quali metodi di pagamento ha a disposizione il cliente?
              </span>
              {selected === 5 ? (
                <FaCaretDown color="white" />
              ) : (
                <FaCaretUp color="white" />
              )}
            </div>
            {selected === 5 ? (
              <motion.p
                className="open"
                initial={{ opacity: 0 }}
                animate={{
                  opacity: 1,
                  transition: {
                    duration: 0.8,
                  },
                }}
                exit={{ opacity: 0 }}
              >
                Tutti quelli disponibili ovvero Carta di credito, Carta di
                debito, Bancomat, Bonifico e contanti.
              </motion.p>
            ) : (
              ''
            )}
            <div className="h5-accordion" onClick={() => toggleAccordion(6)}>
              <span>Quando pagate le provvigioni?</span>
              {selected === 6 ? (
                <FaCaretDown color="white" />
              ) : (
                <FaCaretUp color="white" />
              )}
            </div>
            {selected === 6 ? (
              <motion.p
                className="open"
                initial={{ opacity: 0 }}
                animate={{
                  opacity: 1,
                  transition: {
                    duration: 0.8,
                  },
                }}
                exit={{ opacity: 0 }}
              >
                Entro il 10 del mese successivo.
              </motion.p>
            ) : (
              ''
            )}
          </Accordion>
          <img src={faq} alt="FAQ Foto" className="mobile" />
        </ColTwo>
      </Container>
    </FAQSectionWrapper>
  );
}

export default FAQSection;
