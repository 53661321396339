import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { HomeWrapper } from './Home.styles';
import Navbar from '../components/Navbar';
import { SingleTeamImg, SingleTeamStyles } from './Team.styles';
import Footer from '../components/Footer';
import { getSingleTeam } from '../features/team/teamActions';
import PageLoading from '../common/components/elements/PageLoading';
import { REACT_APP_BLOG_IMG_URL } from '../config';

function SingleTeam() {
  const { teamSlug } = useParams();
  const [show, setShow] = useState(false);
  const { loading, team } = useSelector((store) => store.teams.team);

  const closeMenu = () => {
    if (show === true) {
      setShow(false);
    }
  };

  useEffect(() => {
    getSingleTeam(teamSlug);
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  if (loading) {
    return <PageLoading />;
  }
  return (
    <>
      <Helmet>
        <title>
          Greenia SRL |
          {team.full_name}
        </title>
        <meta name="description" content={team.excerpt} />
        <meta name="keywords" content={team.seo_keywords} />
        <meta
          name="apple-mobile-web-app-title"
          content={`Greenia SRL | ${team.full_name}`}
        />
        <meta
          name="bingbot"
          content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
        />
        <meta
          name="twitter:card"
          content="https://greenia.it/images/logo.png"
        />
        <meta name="twitter:description" content={team.excerpt} />
        <meta
          name="twitter:title"
          content={`Greenia SRL | ${team.full_name}`}
        />
        <meta
          name="twitter:image"
          content="https://greenia.it/images/logo.png"
        />
        <meta property="og:locale" content="it_IT" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`Greenia SRL | ${team.full_name}`} />
        <meta property="og:description" content={team.excerpt} />
        <meta property="og:image" content="https://greenia.it/logo512.png" />
        <meta property="og:url" content="https://greenia.it/" />
        <meta
          property="og:image:secure_url"
          content="https://greenia.it/images/logo.png"
        />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:alt" content="Greenia Logo" />
      </Helmet>

      <HomeWrapper onClick={() => closeMenu()}>
        <Navbar show={show} setShow={setShow} />

        <div className="container">
          <div className="row my-5">
            <div className="col-12 col-lg-6">
              <SingleTeamImg>
                <img
                  src={`${REACT_APP_BLOG_IMG_URL}team/${team.image}`}
                  alt={team.full_name}
                  className=""
                />
              </SingleTeamImg>
            </div>
            <div className="ol-12 col-lg-6 d-flex flex-column justify-content-center align-items-start">
              <SingleTeamStyles>
                <div className="mt-2 mb-5 ">
                  <div className="d-flex flex-column justify-content-start align-items-start">
                    <div className="mt-4 px-0 px-xxl-5">
                      <h2>{team.full_name}</h2>
                      <div className="d-flex justify-content-start align-items-start mt-2 gap-1">
                        <h3>{team.role}</h3>
                      </div>
                    </div>
                  </div>
                  <div className="mt-3 px-0 px-xxl-5">
                    <p dangerouslySetInnerHTML={{ __html: team.body }} />
                  </div>
                </div>
              </SingleTeamStyles>
            </div>
          </div>
        </div>
        <Footer />
      </HomeWrapper>
    </>
  );
}

export default SingleTeam;
