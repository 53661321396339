import styled from 'styled-components';
import { motion } from 'framer-motion';
import { PrimaryColor } from '../utils/StyleVariables';

export const ProductsInfoWrapper = styled.section`
  /* max-width: 1440px; */
  width: 100%;
  margin: 0 auto;
  background-color: #eee;
  padding: 10px 0;

  & .container {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  /* @media (max-width: 999px) {
    max-width: 85vw;
  } */
`;

export const ProductInfoRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 50px 0;

  @media (max-width: 999px) {
    margin: 50px 0;
  }
`;

export const ImageContainer = styled.div`
  width: 50%;

  &.start {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &.end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  img {
    width: 90%;
  }

  @media (max-width: 999px) {
    display: none !important;
  }
`;

export const InfoContainer = styled(motion.div)`
  width: 50%;
  padding: 10px;

  h3 {
    margin-top: 10px;
    font-family: "Tobi Pro", sans-serif;
    font-size: 26px;
    color: ${PrimaryColor};
  }

  img {
    width: 70px;
  }

  @media (max-width: 999px) {
    width: 100%;
    padding: 0;
  }
`;
