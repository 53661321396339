import React from 'react';
import { FaEnvelope, FaMapMarkerAlt, FaPhone } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import {
  FirstCol,
  FooterRow,
  FooterWrapper,
  SecondCol,
  ThirdCol,
} from './Footer.styles';
import logoWhite from '../assets/images/logo-white.svg';
import privacy from '../assets/document/Privacy_Greenia.pdf';
import axe from '../services/axios-client/axiosClient';

export const downloadFile = (fileBlob) => {
  const file = window.URL.createObjectURL(fileBlob);
  window.open(file, '_blank');
};

function Footer() {
  const downloadResourceDocs = async () => {
    const res = await axe.get('/greenia-precontract', {
      responseType: 'blob',
    });

    downloadFile(res.data);
  };

  const downloadDeclarationDoc = async () => {
    const res = await axe.get('/greenia-dichiarazione-di-intenti', {
      responseType: 'blob',
    });

    downloadFile(res.data);
  };

  const proceduraPresentazioneReclamiIvass = async () => {
    const res = await axe.get('/procedura-di-presentazione-reclami-ivass', {
      responseType: 'blob',
    });

    downloadFile(res.data);
  };

  const proceduraReclamiGreenia = async () => {
    const res = await axe.get('/procedura-reclami-greenia', {
      responseType: 'blob',
    });

    downloadFile(res.data);
  };

  return (
    <FooterWrapper>
      <FooterRow>
        <FirstCol>
          <img src={logoWhite} alt="Greenia Logo" />
          <h3>New insurance solutions</h3>
          <div className="h6-div">Sede & Uffici</div>
          <ul>
            <li>Greenia Srl Verona (VR), Viale del Lavoro 53, 37135</li>
            <li>
              PEC:
              {' '}
              <a href="mailto:greeninsuranceagency@legalmail.it">
                greeninsuranceagency@legalmail.it
              </a>
              {' '}
              , partita iva 16804361000
            </li>
            <li>Numero di iscrizione rui: B000714748</li>
          </ul>
        </FirstCol>
        <SecondCol>
          <div className="h6-div">Risorse</div>
          <ul>
            <li>
              <a href={privacy} target="_blank" rel="noreferrer">
                Informativa Privacy Greenia
              </a>
            </li>
            <li>
              <Link to="/privacy-policy">Cookie Policy</Link>
            </li>
            <li>
              <Link to="/compagnie-confrontate">Compagnie Confrontate</Link>
            </li>
            <li onClick={() => downloadResourceDocs()}>
              <p>Modello Unico Precontrattuale</p>
            </li>
            <li onClick={() => downloadDeclarationDoc()}>
              <p>Dichiarazione d'intenti</p>
            </li>
            <li onClick={() => proceduraPresentazioneReclamiIvass()}>
              <p>Procedura di Presentazione Reclami Ivass</p>
            </li>
            <li onClick={() => proceduraReclamiGreenia()}>
              <p>Procedura Reclami Greenia</p>
            </li>
          </ul>
        </SecondCol>
        <ThirdCol>
          <div className="h6-div">Contatti</div>
          <ul>
            <li>
              <FaEnvelope />
              {' '}
              <a href="mailto:amministrazione@greenia.it">
                amministrazione@greenia.it
              </a>
            </li>
            <li>
              <FaPhone />
              {' '}
              <a href="tel:0685875144">0685875144</a>
            </li>
            <li>
              <FaMapMarkerAlt />
              {' '}
              Verona (VR), Viale del Lavoro 53, 37135
            </li>
          </ul>
        </ThirdCol>
      </FooterRow>
    </FooterWrapper>
  );
}

export default Footer;
