import React from 'react';
import { FaArrowRight, FaBars, FaTimes } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import {
  Logo,
  NavMenu,
  NavMenuMobile,
  NavbarStyles,
  ToggleButton,
} from './Navbar.styles';
import { HeaderWrapper } from '../pages/Home.styles';
import logoGreen from '../assets/images/logo-green.svg';

function Navbar({ show, setShow }) {
  const toggleMenu = () => {
    setShow(!show);
  };

  return (
    <HeaderWrapper>
      <NavbarStyles>
        <Logo href="/">
          <img src={logoGreen} alt="Greenia SLR" width={200} />
        </Logo>
        <ToggleButton onClick={() => toggleMenu()}>
          {show ? <FaTimes /> : <FaBars />}
        </ToggleButton>
        <NavMenu>
          <ul className="mb-0">
            <li className="pb-0">
              <Link to="/#our-products">Prodotti</Link>
            </li>
            <li className="pb-0">
              <a href="/#chi-siamo">Chi Siamo</a>
            </li>

            <li className="pb-0">
              <Link to="/collabora-con-noi">Collabora Con Noi</Link>
            </li>
            <li className="pb-0">
              <a href="/#faq">FAQ</a>
            </li>
            <li className="pb-0">
              <a href="/blog">Blog</a>
            </li>
            <li className="pb-0">
              <a href="/team">Team</a>
            </li>
            <li className="pb-0">
              <a href="/login">Area Clienti</a>
            </li>
          </ul>
          <a
            href="https://intermediari.greenia.it"
            target="_blank"
            className="bookmarkBtn text-decoration-none"
            rel="noreferrer"
          >
            <span className="IconContainer">
              <FaArrowRight className="text-dark" />
            </span>
            <p className="text m-0 me-2">Accedi</p>
          </a>
        </NavMenu>
        {show && (
          <NavMenuMobile>
            <ul>
              <li>
                <a href="#product-info">Prodotti</a>
              </li>
              <li>
                <a href="#chi-siamo">Chi Siamo</a>
              </li>
              <li>
                <Link to="/collabora-con-noi">Collabora Con Noi</Link>
              </li>
              <li>
                <a href="#faq">FAQ</a>
              </li>
              <li>
                <a href="/blog">Blog</a>
              </li>
              <li className="pb-0">
                <a href="/team">Team</a>
              </li>
              <li className="pb-0">
                <a href="/login">Area Clienti</a>
              </li>
            </ul>
            <a
              href="https://intermediari.greenia.it"
              target="_blank"
              className="bookmarkBtn text-decoration-none"
              rel="noreferrer"
            >
              <span className="IconContainer">
                <FaArrowRight className="text-dark" />
              </span>
              <p className="text m-0 me-2">Accedi</p>
            </a>
          </NavMenuMobile>
        )}
      </NavbarStyles>
    </HeaderWrapper>
  );
}

export default Navbar;
