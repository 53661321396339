import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { motion } from 'framer-motion';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector } from 'react-redux';
import ReCAPTCHA from 'react-google-recaptcha';
import { useNavigate } from 'react-router';
import Footer from '../components/Footer';
import { HeaderStyles, HomeWrapper } from './Home.styles';
import Navbar from '../components/Navbar';
import {
  OnBoardingButton,
  OnBoardingInput,
  OnBoardingInputSection,
  RadioButton,
  RecaptchaDiv,
  RobotHead,
} from './OnBoarding.styles';
import robotHead from '../assets/images/robot-head.png';
import PageLoading from '../common/components/elements/PageLoading';
import { chatFormToken } from '../features/chatForm/chatFormActions';
import { generalAlertError } from '../common/utils/alerts';

const schema = yup.object({
  section: yup.string().required('La sezione e un campo obbligatorio!'),
  name: yup.string().required('Il nome e un campo obbligatorio!'),
  surname: yup.string().required('Il cognome e un campo obbligatorio!'),
  email: yup
    .string()
    .required('La email e un campo obbligatorio!')
    .email("Deve essere un'e-mail valida"),
  accept_terms: yup.string().required('Devi accetare i termini per continuare!'),
});

function OnBoarding() {
  const [show, setShow] = useState(false);
  const recaptchaRef = useRef(null);
  const [token, setToken] = useState(null);
  const [index, setIndex] = useState(0);
  const navigate = useNavigate();
  const { loading } = useSelector((store) => store.chatForm.formData);
  const {
    register,
    formState: { errors },
    watch,
    trigger,
    setValue,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
      surname: '',
      email: '',
      section: '',
      accept_terms: '0',
    },
  });

  const onChangeRecaptcha = (recaptchaToken) => {
    setToken(recaptchaToken);
  };

  const resetRecaptcha = () => {
    if (recaptchaRef.current) {
      recaptchaRef.current.reset();
    }
  };
  const formData = watch();

  const sendData = async () => {
    const val = await trigger(['email', 'name', 'surname']);
    if (val === false) {
      return;
    }
    if (token === null) {
      generalAlertError('Seleziona Recaptcha');
      return;
    }

    if (formData.accept_terms === '0') {
      generalAlertError('Devi accettare i termini!');
      return;
    }

    if (formData.accept_terms === '1') {
      chatFormToken(formData, resetRecaptcha, { Captcha: token }, navigate);
    }
  };

  const closeMenu = () => {
    if (show === true) {
      setShow(false);
    }
  };

  const handleInitialStep = () => {
    if (index === 0) {
      setIndex(index + 1);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  const handleAcceptTerms = (e) => {
    if (e.target.checked === true) {
      localStorage.setItem('accept_terms', '1');
      setValue('accept_terms', '1');
    } else {
      localStorage.setItem('accept_terms', '0');
      setValue('accept_terms', '0');
    }
  };

  const handleNextStep = () => {
    if (formData.section !== '') {
      setIndex(index + 1);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      trigger('section');
    }
  };

  const handleSecondStep = () => {
    if (formData.name !== '' || formData.surname !== '') {
      setIndex(index + 1);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      trigger(['name', 'surname']);
    }
  };

  useEffect(
    () => () => {
      resetRecaptcha();
      recaptchaRef.current = null;
    },
    [],
  );

  const motionVariants = {
    offscreen: { opacity: 0, x: 20 },
    onscreen: {
      opacity: 1,
      x: 0,

      transition: {
        duration: 0.7,
      },
    },
  };

  return (
    <>
      <Helmet>
        <title>Greenia SRL | Il Nostro Assistente Virtuale</title>
        <meta
          name="description"
          content="Greenia offre un ecosistema phygital all’avanguardia su misura per gli assicuratori, integrando perfettamente i progressi digitali con un servizio personalizzato per migliorare l’esperienza assicurativa."
        />
      </Helmet>
      <HomeWrapper onClick={() => closeMenu()}>
        <HeaderStyles>
          <Navbar show={show} setShow={setShow} />
          {loading ? (
            <PageLoading />
          ) : (
            <OnBoardingInputSection
              variants={motionVariants}
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ once: true, amount: 0.6 }}
            >
              <RobotHead>
                <img src={robotHead} alt="" />
              </RobotHead>
              {index === 0 && (
                <>
                  <motion.div
                    variants={motionVariants}
                    initial="offscreen"
                    whileInView="onscreen"
                    viewport={{ once: true, amount: 0.8 }}
                    className="mt-5 understand-text py-4 px-5"
                  >
                    <h4 className="text-center mb-3">Nota</h4>
                    <h5 className="text-center m-0">
                      Interagendo con questo chatbot assistito da un'IA, potrai
                      rapidamente ottenere informazioni su offerte dedicate agli
                      intermediari assicurativi. Ti invitiamo a formulare
                      domande specifiche sull'argomento di tuo interesse.
                      Tuttavia, tieni presente che questo chatbot è ancora in
                      fase sperimentale e le risposte potrebbero non essere del
                      tutto accurate. Per ulteriori approfondimenti, ti
                      consigliamo di contattare direttamente il nostro personale
                      o di visitare il nostro sito web.
                    </h5>
                    <div className="d-flex gap-2 justify-content-center align-items-center mt-3">
                      <h5>Supporto:</h5>
                      <a
                        href="tel:0685875144"
                        className="text-decoration-none text-success"
                      >
                        <h5 className="text-success">0685875144</h5>
                      </a>
                    </div>
                  </motion.div>
                  <OnBoardingButton>
                    <button onClick={() => handleInitialStep()}>
                      Ho capito
                    </button>
                  </OnBoardingButton>
                </>
              )}
              {index === 1 && (
                <>
                  <motion.h1
                    variants={motionVariants}
                    initial="offscreen"
                    whileInView="onscreen"
                    viewport={{ once: true, amount: 0.8 }}
                  >
                    Ciao, Io Sono Mellino.
                  </motion.h1>
                  <motion.h2
                    variants={motionVariants}
                    initial="offscreen"
                    whileInView="onscreen"
                    viewport={{ once: true, amount: 0.8 }}
                  >
                    Tu sei un intermediario di sezione?
                  </motion.h2>
                  <motion.div
                    variants={motionVariants}
                    initial="offscreen"
                    whileInView="onscreen"
                    viewport={{ once: true, amount: 0.8 }}
                  >
                    <RadioButton>
                      <input
                        type="radio"
                        id="RadioButton"
                        value="A"
                        {...register('section')}
                      />
                      <label htmlFor="RadioButton">A</label>
                    </RadioButton>
                    <RadioButton>
                      <input
                        type="radio"
                        id="RadioButton"
                        value="B"
                        {...register('section')}
                      />
                      <label htmlFor="RadioButton">B</label>
                    </RadioButton>
                    <RadioButton>
                      <input
                        type="radio"
                        id="RadioButton"
                        value="E"
                        {...register('section')}
                      />
                      <label htmlFor="RadioButton">E</label>
                    </RadioButton>
                    {errors.section?.message && (
                      <p>{errors.section?.message}</p>
                    )}
                  </motion.div>

                  <OnBoardingButton>
                    <button onClick={() => handleNextStep()}>Continua</button>
                  </OnBoardingButton>
                </>
              )}
              {index == 2 && (
                <>
                  <motion.h1
                    variants={motionVariants}
                    initial="offscreen"
                    whileInView="onscreen"
                    viewport={{ once: true, amount: 0.8 }}
                  >
                    Prima di iniziare,
                    <br />
                    Dimmi qualcosa di te...
                  </motion.h1>
                  <OnBoardingInput
                    variants={motionVariants}
                    initial="offscreen"
                    whileInView="onscreen"
                    viewport={{ once: true, amount: 0.8 }}
                  >
                    <input
                      type="text"
                      id="name"
                      placeholder="Il tuo nome..."
                      value={formData.name}
                      maxLength={255}
                      {...register('name')}
                    />
                    {errors.name?.message && <p>{errors.name?.message}</p>}
                  </OnBoardingInput>
                  <OnBoardingInput
                    variants={motionVariants}
                    initial="offscreen"
                    whileInView="onscreen"
                    viewport={{ once: true, amount: 0.8 }}
                  >
                    <input
                      type="text"
                      id="surname"
                      name="surname"
                      placeholder="Il tuo cognome..."
                      value={formData.surname}
                      maxLength={255}
                      {...register('surname')}
                    />
                    {errors.surname?.message && (
                      <p>{errors.surname?.message}</p>
                    )}
                  </OnBoardingInput>
                  <OnBoardingButton>
                    <button onClick={() => handleSecondStep()}>Continua</button>
                  </OnBoardingButton>
                </>
              )}
              {index == 3 && (
                <>
                  <motion.h1
                    variants={motionVariants}
                    initial="offscreen"
                    whileInView="onscreen"
                    viewport={{ once: true, amount: 0.8 }}
                  >
                    Puoi Fornirci i Tuoi Recapiti?
                  </motion.h1>
                  <div className="d-flex flex-column justify-content-center align-items-center">
                    <OnBoardingInput
                      variants={motionVariants}
                      initial="offscreen"
                      whileInView="onscreen"
                      viewport={{ once: true, amount: 0.8 }}
                    >
                      <input
                        type="text"
                        id="email"
                        name="email"
                        placeholder="L'indirizzo email..."
                        value={formData.email}
                        maxLength={255}
                        {...register('email')}
                      />
                      {errors.email?.message && <p>{errors.email?.message}</p>}
                    </OnBoardingInput>

                    <motion.div
                      className="accept-terms mt-3"
                      variants={motionVariants}
                      initial="offscreen"
                      whileInView="onscreen"
                      viewport={{ once: true, amount: 0.8 }}
                    >
                      <div
                        className="form-check"
                        onClick={(e) => handleAcceptTerms(e)}
                      >
                        <input className="form-check-input" type="checkbox" />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckDefault"
                        >
                          Accetto e confermo di aver preso visione
                          dell'informativa ai sensi dell'art. 13 del regolamento
                          UE 679/2016, acconsentendo al trattamento dei miei
                          dati secondo le finalità ivi specificate.
                        </label>
                      </div>
                    </motion.div>
                  </div>

                  <RecaptchaDiv>
                    <ReCAPTCHA
                      sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                      onChange={onChangeRecaptcha}
                      ref={recaptchaRef}
                      onExpired={() => {}}
                    />
                  </RecaptchaDiv>

                  <OnBoardingButton
                    variants={motionVariants}
                    initial="offscreen"
                    whileInView="onscreen"
                    viewport={{ once: true, amount: 0.8 }}
                  >
                    <button onClick={() => sendData()}>Invia</button>
                  </OnBoardingButton>
                </>
              )}
            </OnBoardingInputSection>
          )}
        </HeaderStyles>
        <Footer />
      </HomeWrapper>
    </>
  );
}

export default OnBoarding;
